/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { apirc } from '~/configs/apirc';
import { SignalrMethod } from '~/modules/SDK/Signalr/SignalrMethod';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
/** 保持 uid 同步給後端 */
export const useSignalrSetUid2 = () => {
    const me = useSnapshot(fr_me).me;
    useProxy(signalrStore2);
    /** 為當前的連線，配置 Uid 發給後端知道 */
    useEffect(() => {
        if (!me.uid)
            return;
        if (!signalrStore2.isConnected)
            return;
        signalrStore2.connection.invoke(SignalrMethod.SetUid, me.uid).catch((error) => {
            if (error.message.includes('Method does not exist')) {
                console.error(`[signalr/react@2] 在 ${fr_agents.agent} 的後端尚未支持 ${SignalrMethod.SetUid} 方法，如須此特性，請聯絡後端支持`, { wsURL: apirc.signalr.wsURL });
            }
        });
    }, [me.uid, signalrStore2.isConnected]);
};
